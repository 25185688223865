<template>
  <b-modal
    id="details-modal"
    size="lg"
    title="جزئیات رسانه"
    ok-title="بستن"
    centered
    ok-only
  >
    <template v-if="tempImage">
      <b-container fluid>
        <b-form-row>
          <b-col cols="6">
            <img
              v-if="mediaType == 'image'"
              :src="handleImageUrl(tempImage)"
              alt=""
              class="w-100 h-auto"
            />
            <video
              v-else
              :src="handleImageUrl(tempImage)"
              class="w-100 h-auto"
              controls
            ></video>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="mediaType == 'image' ? 'آدرس عکس' : 'آدرس ویدیو'"
            >
              <b-form-input
                disabled
                :value="handleImageUrl(tempImage)"
              ></b-form-input>
            </b-form-group>
            <div class="w-100 d-flex align-items-center justify-content-end">
              <b-button
                size="sm"
                variant="primary"
                class="mb-2"
                @click="copyUrl(handleImageUrl(tempImage))"
              >
                کپی کردن
              </b-button>
            </div>
            <b-form-group label="تاریخ آپلود" v-if="tempImage.createDate">
              <b-form-input
                disabled
                :value="
                  new Date(tempImage.createDate).toLocaleDateString('fa-IR')
                "
              ></b-form-input>
            </b-form-group>
            <div class="w-100 d-flex align-items-center justify-content-end">
              <b-button
                v-b-modal.media-delete-modal
                variant="danger"
                size="sm"
                class="my-1"
              >
                {{ mediaType == "image" ? "حذف عکس" : "حذف ویدیو" }}
              </b-button>
            </div>
          </b-col>
        </b-form-row>
      </b-container>
      <b-modal
        id="media-delete-modal"
        :title="mediaType == 'image' ? 'حذف عکس' : 'حذف ویدیو'"
        ok-variant="danger"
        ok-title="بله"
        cancel-title="بستن"
        centered
        @ok="sendDeleteRequest"
      >
        <span>آیا از حذف رسانه اطمینان دارید؟</span>
      </b-modal>
    </template>
  </b-modal>
</template>

<script>
export default {
  emits: ["refetch"],
  props: {
    tempImage: {
      type: Object,
    },
    mediaType: {
      // image, video
      type: String,
      required: false,
      default: "image",
    },
  },
  methods: {
    async sendDeleteRequest() {
      try {
        let _this = this;
        let deleteAMedia = new DeleteAMedia(_this);
        deleteAMedia.setRequestParam({
          pictureUrl: _this.tempImage.pictureUrl,
        });
        await deleteAMedia.fetch((response) => {
          if (response.isSuccess) {
            _this.$emit("refetch");
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `رسانه با موفقیت حذف شد.`,
              },
            });
            _this.$bvModal.hide("details-modal");
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    copyUrl(imageUrl) {
      if (navigator) {
        navigator.clipboard.writeText(imageUrl);
        this.$toast({
          component: ToastificationContent,
          position: "bottom-center",
          props: {
            title: `عملیات موفق`,
            icon: "CheckIcon",
            variant: "success",
            text: `لینک با موفقیت کپی شد.`,
          },
        });
      }
    },
    handleImageUrl(image) {
      return `${Helper.baseUrl}/Media/Gallery/Library/${image.pictureUrl}`;
    },
  },
  components: {
    BModal,
    BContainer,
    BFormRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
  },
};
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Helper from "@/libs/Helper";
import { DeleteAMedia } from "@/libs/Api/Media";
import {
  BModal,
  BContainer,
  BFormRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
</script>
