<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center">
        <h1>کتابخانه</h1>
      </div>
      <b-row>
        <b-col
          @click="openImageDetails(image)"
          cols="2"
          v-for="image in libraryList"
          :key="image.mediaId"
          class="cursor-pointer"
        >
          <img :src="handleImageUrl(image)" alt="" class="w-100 h-auto" />
        </b-col>
      </b-row>
      <div class="my-2 d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="pageNumber"
          :total-rows="totalCount"
          :per-page="count"
          first-number
          align="center"
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              style="transform: rotate(180deg)"
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
      <library-image-modal
        :tempImage="tempImage"
        @refetch="getAllLibraryFiles"
      ></library-image-modal>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "کتابخانه",
  mounted() {
    this.getAllLibraryFiles();
  },
  data() {
    return {
      isLoading: false,
      libraryList: null,
      count: 20,
      pageNumber: 1,
      totalCount: null,
      tempImage: null,
    };
  },
  watch: {
    pageNumber: {
      handler(val) {
        this.getAllLibraryFiles();
      },
    },
  },
  methods: {
    copyUrl(imageUrl) {
      if (navigator) {
        navigator.clipboard.writeText(imageUrl);
        this.$toast({
          component: ToastificationContent,
          position: "bottom-center",
          props: {
            title: `عملیات موفق`,
            icon: "CheckIcon",
            variant: "success",
            text: `لینک با موفقیت کپی شد.`,
          },
        });
      }
    },
    openImageDetails(image) {
      this.tempImage = JSON.parse(JSON.stringify(image));
      this.$bvModal.show("details-modal");
    },
    handleImageUrl(image) {
      return `${Helper.baseUrl}/Media/Gallery/Library/${image.pictureUrl}`;
    },
    async getAllLibraryFiles() {
      try {
        this.isLoading = true;
        let _this = this;
        let getLibraryFiles = new GetLibraryFiles(_this);
        getLibraryFiles.setRequestParam({
          count: _this.count,
          pageNumber: _this.pageNumber,
        });
        await getLibraryFiles.fetch((response) => {
          if (response.isSuccess) {
            _this.libraryList = response.data.medias;
            _this.totalCount = response.data.mediasCount;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BModal,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
    LibraryImageModal,
  },
};
import { GetLibraryFiles } from "@/libs/Api/Media";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Helper from "@/libs/Helper";
import LibraryImageModal from "@/views/components/utils/LibraryImageModal.vue";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BModal,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
</script>
